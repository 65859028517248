import React from 'react'
import Autosuggest from 'react-autosuggest'
import './style.css'
import axios from 'axios'
import { dbUrl } from '../Consts'

class Suggest extends React.Component {
  state = {
    value: '',
    suggestions: []
  }

  getSuggestionValue = (suggestion) => {
    this.props.setIdToLocalStorage(suggestion)
    return String(suggestion.ticker)
  }

  renderSuggestion = suggestion => (
    <div>
      {String(suggestion.ticker)}
    </div>
  )

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    })
  }

  onSuggestionsFetchRequested = async ({ value }) => {
    this.setState({
      suggestions: (await axios.get(`${dbUrl}/api/get_suggest_list/${value}`)).data
    })
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    })
  }

  render () {
    const { value, suggestions } = this.state

    const inputProps = {
      placeholder: '新しくポートフォリオに追加',
      value,
      onChange: this.onChange
    }

    return (
      <span className={this.props.className}>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
        />
      </span>
    )
  }

}

export default Suggest