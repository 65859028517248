import React from 'react'
import { getDividendPrice, getDividendOnePrice } from '../functions/getDividendPrice'
import DeleteIcon from '@mui/icons-material/Delete'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'

class StockItem extends React.Component {
  state = {
    firstTouchValue: 0,
    menuValue: 0,
    deleteClass: false,
    is_showed: false
  }

  touchFirstEvent = (e) => {
    this.setState({
      firstTouchValue: e.targetTouches[0].clientX
    })
  }

  moveTouchEvent = (e) => {
    const value = this.state.menuValue + ((this.state.firstTouchValue - e.targetTouches[0].clientX) / 4)
    if (value > 10 || this.state.deleteClass) {
      this.setState({
        menuValue: value - 10
      })
    }
  }

  touchEndEvent = (e) => {
    const maxValue = 100
    if (this.state.menuValue > maxValue) {
      this.setState({
        menuValue: maxValue,
        deleteClass: true
      })
    }
    if (this.state.menuValue < maxValue) {
      this.setState({
        menuValue: 0,
        deleteClass: false
      })
    }
  }

  touchEventForToggleItem = () => {
    this.setState({
      is_showed: !this.state.is_showed
    })
  }

  render () {
    const { stock } = this.props
    return (
      <li className={`index-stock_list--item ${this.state.is_showed ? 'is_show' : ''}`} onTouchStart={this.touchFirstEvent} onTouchMove={this.moveTouchEvent} onTouchEnd={this.touchEndEvent}>
        <span className={`index-stock_list--color`} name={stock.id} style={{ background: stock.color }} onClick={this.props.clickShowingColorPicker}> </span>
        <header className={`index-stock_list--header`} onTouchStart={this.touchEventForToggleItem}>
          <span className={`index-stock_list--ticker`}>{stock.ticker}</span>
          <span className={`index-stock_list--current_price`}>{getDividendPrice(stock).toLocaleString()}円</span>
          <span className={`index-stock_list--company_name`}>{stock.company_name}</span>
        </header>
        <section className={`index-stock_list--body`}>
          <div className={`index-stock_list--prices`}>
            <span className={`index-stock_list--stock_price`}>株価: <span className={`index-stock_list--price`}>{Number(stock.stock_price).toLocaleString()}</span>円</span>
            <span className={`index-stock_list--stock_price`}>１株当り配当金: <span className={`index-stock_list--price`}>{getDividendOnePrice(stock).toLocaleString()}</span>円</span>
          </div>
          <span className={`index-stock_list--monthly`}>
            配当予定月:
            {stock.dividend_months.map((month, index) => (
              <span key={index}>{month.month}</span>
            ))}
          </span>
        </section>
        <section className={`index-stock_list--controller`}>
          <AddCircleIcon sx={{ fontSize: 24 }} name={stock.id} className={`index-stock_list--controller_button`} onClick={this.props.clickIncreasingAmountEvent}/>
          <span className={`index-stock_list--stock_number`}>{stock.amount}</span>
          <RemoveCircleIcon sx={{ fontSize: 24 }} name={stock.id} className={`index-stock_list--controller_button`} onClick={this.props.clickDecreasingAmountEvent}/>
        </section>
        <button name={stock.id} className={`index-stock_list--remove_button`} style={{ width: this.state.menuValue }} onClick={this.props.deleteStockEvent}>
          <DeleteIcon sx={{ fontSize: 32 }}/></button>
      </li>
    )
  }
}

export default StockItem