import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import Suggest from './Suggest/Suggest'
import reportWebVitals from './reportWebVitals'
import axios from 'axios'
import MonthlyChart from './MonthlyChart/MonthlyChart'
import { getDividendPrice } from './functions/getDividendPrice'
import StockItem from './StockItem/StockItem'
import { Fab } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { herokuUrl, dbUrl } from './Consts'
import PieChart from './PieChart/PieChart'
import moment from 'moment'
import ColorPicker from './ColorPicker/ColorPicker'

class Index extends React.Component {
  state = {
    stocks: [],
    getStockValue: '',
    isShowedSuggestButton: false,
    isShowedColorPicker: false,
    rate: 0,
    rateUpdateDay: '',
    selectedStockId: 0,
  }
  localStorage = []
  monthlyStocks = [[], [], [], [], [], [], [], [], [], [], [], []]

  componentDidMount = async () => {
    this.localStorage = localStorage.getItem('stocks') !== null ? JSON.parse(localStorage.getItem('stocks')) : []
    for (let i = 0; i < this.localStorage.length; i++) {
      await this.setStocksByLocalStorage(this.localStorage[i])
    }

    const rate = (await axios.get(`${dbUrl}/api/get_rate`)).data
    this.setState({
      rate: rate.rate,
      rateUpdateDay: rate.updated_at
    })
  }

  setStocksByLocalStorage = async (localStorage) => {
    const stock = (await axios.get(`${dbUrl}/api/stock/${localStorage.id}`)).data
    const array = this.state.stocks
    if (localStorage.amount) stock.amount = localStorage.amount
    if (!localStorage.amount) stock.amount = 0
    stock.color = localStorage.color
    array.unshift(stock)
    this.setState({
      stocks: array
    })
  }

  setIdToLocalStorage = async (value) => {
    // 既にリストに表示があるものは省く
    let isExisted = false
    this.state.stocks.forEach((stock) => {
      if (stock.id === value.id) isExisted = true
    })

    if (isExisted) return
    const stock = (await axios.get(`${dbUrl}/api/stock/${value.id}`)).data
    const array = this.state.stocks
    stock.amount = 0
    stock.color = '#cccccc'
    array.unshift(stock)
    this.setState({
      stocks: array
    })
    this.localStorage.unshift({ id: stock['id'], amount: stock['amount'], color: stock['color'], })
    localStorage.setItem('stocks', JSON.stringify(this.localStorage))
  }

  changeAmountEvent = (e) => {
    const value = e.target.value
    const id = e.target.name
    if (e.target.value < 0) return
    this.localStorage.forEach((localStorage) => {
      if (localStorage.id === Number(id)) {
        localStorage['amount'] = value
      }
    })

    const array = this.state.stocks.map((stock) => {
      if (stock.id === Number(id)) {
        stock.amount = Number(value)
      }
      return stock
    })

    this.setState({ stocks: array })
    localStorage.setItem('stocks', JSON.stringify(this.localStorage))
  }

  clickShowingColorPicker = (e) => {
    const id = e.target.getAttribute('name')
    this.setState({
      isShowedColorPicker: true,
      selectedStockId: id
    })
  }

  changeColorEvent = (color) => {
    this.localStorage.forEach((localStorage) => {
      if (localStorage.id === Number(this.state.selectedStockId)) {
        localStorage['color'] = color
      }
    })

    const array = this.state.stocks.map((stock) => {
      if (stock.id === Number(this.state.selectedStockId)) {
        stock.color = color
      }
      return stock
    })

    this.setState({ stocks: array, selectedStockId: null, isShowedColorPicker: false })
    localStorage.setItem('stocks', JSON.stringify(this.localStorage))
  }

  deleteStockEvent = (e) => {
    const id = e.target.name
    this.localStorage = this.localStorage.filter((localStorage) => {
      return localStorage.id !== Number(id)
    })

    const array = this.state.stocks.filter((stock) => {
      return stock.id !== Number(id)
    })

    this.setState({ stocks: array })
    localStorage.setItem('stocks', JSON.stringify(this.localStorage))
  }

  calculateMaxDividendValue = () => {
    let maxValue = 0
    this.state.stocks.forEach((stock) => {
      maxValue += getDividendPrice(stock)
    })
    return maxValue
  }

  calculateMaxValue = () => {
    let maxValue = 0
    this.state.stocks.forEach((stock) => {
      maxValue += stock.stock_price * stock.amount
    })
    return maxValue
  }

  calculateAverageDividendPer = () => {
    let value = 0
    if (!(100 / (this.calculateMaxValue() / this.calculateMaxDividendValue()))) {
      value = 0
    } else {
      value = (100 / (this.calculateMaxValue() / this.calculateMaxDividendValue())).toFixed(1)
    }
    return value
  }

  calculateMaxDividend = () => {
    let tmp = 0
    let id = 0
    let ticker = {}
    this.state.stocks.forEach((stock) => {
      if (tmp < stock.stock_yield) {
        tmp = stock.stock_yield
        id = stock.id
      }
    })
    this.state.stocks.forEach((stock) => {
      if (stock.id === id) {
        ticker = stock
      }
    })
    return ticker['company_name']
  }

  calculateMaxTicker = () => {
    let tmp = 0
    let id = 0
    let maxTicker = {}
    this.state.stocks.forEach((stock) => {
      const dividendValue = getDividendPrice(stock)
      if (tmp < dividendValue) {
        tmp = dividendValue
        id = stock.id
      }
    })

    this.state.stocks.forEach((stock) => {
      if (stock.id === id) {
        maxTicker = stock
      }
    })
    return maxTicker['company_name']
  }

  changeStockValueEvent = (value) => {
    this.setState({
      getStockValue: value.target.value
    })
  }

  getStockEvent = async () => {
    const ticker = this.state.getStockValue.toLowerCase()
    await axios.get(`${herokuUrl}/set_stock/${ticker}`)
      .then((e) => {
        alert('取得に成功しました')
      })
      .catch((e) => {
        console.log(e)
        alert('取得に失敗')
      })
  }

  openSuggestButtonEvent = () => {
    this.setState({
      isShowedSuggestButton: !this.state.isShowedSuggestButton
    })
  }

  clickIncreasingAmountEvent = (e) => {
    const id = e.target.getAttribute('name')

    this.localStorage.forEach((localStorage) => {
      if (localStorage.id === Number(id)) {
        localStorage['amount'] = Number(localStorage['amount']) + 1
      }
    })

    const array = this.state.stocks.map((stock) => {
      if (stock.id === Number(id)) {
        stock.amount = Number(stock['amount']) + 1
      }
      return stock
    })

    this.setState({ stocks: array })
    localStorage.setItem('stocks', JSON.stringify(this.localStorage))
  }

  clickDecreasingAmountEvent = (e) => {
    const id = e.target.getAttribute('name')

    this.localStorage.forEach((localStorage) => {
      if (localStorage.id === Number(id)) {
        if (Number(localStorage['amount']) > 0) {
          localStorage['amount'] = Number(localStorage['amount']) - 1
        }

      }
    })

    const array = this.state.stocks.map((stock) => {
      if (stock.id === Number(id)) {
        if (Number(stock['amount']) > 0) {
          stock.amount = Number(stock['amount']) - 1
        }
      }
      return stock
    })

    this.setState({ stocks: array })
    localStorage.setItem('stocks', JSON.stringify(this.localStorage))
  }

  render () {
    this.state.stocks.forEach((stock) => {
      stock.dividend_months.forEach((month) => {
        const monthNumber = Number(month.month.replace('月', '')) - 1
        this.monthlyStocks[monthNumber].push(stock)
      })
    })
    return (
      <div>
        <React.StrictMode>

          <section className={`index-main_section ${this.state.isShowedSuggestButton ? 'is_backed' : ''}`}>
            <main className={`index-max_value_section`}>
              <section className={`index-pie_chart`}>
                <PieChart stocks={this.state.stocks}/>
              </section>
              <div className={`index-max_value_section--inner`}>
                <h2 className={`index-max_value_section--title`}>年間配当予想額</h2>
                <span className={`index-max_value_section--value`}>
              {this.calculateMaxDividendValue().toLocaleString()}
                  <span className={`index-max_value_section--type`}>円</span>
            </span>
                <span className={`index-max_value_section--note`}>[総資産評価額：{this.calculateMaxValue().toLocaleString()}円]</span>
                <span className={`index-max_value_section--note`}> [年利：{this.calculateAverageDividendPer()}%]</span>
              </div>
            </main>

            <section className={`index-monthly`}>
              <MonthlyChart stocks={this.state.stocks}/>
            </section>

            <section className={`index-stocks`}>
              <h2 className={`index-title`}>銘柄一覧
                <span className={`index-title--note`}>
              1ドル:{Math.floor(this.state.rate)}円 ({moment(this.state.rateUpdateDay, 'YYYY-MM-DD').format('YYYY年MM月DD日')})
              </span>
              </h2>

              <ul className={`index-stock_list`}>
                {this.state.stocks.map((stock, index) => (
                  <StockItem
                    key={index}
                    stock={stock}
                    clickShowingColorPicker={this.clickShowingColorPicker}
                    clickIncreasingAmountEvent={this.clickIncreasingAmountEvent}
                    clickDecreasingAmountEvent={this.clickDecreasingAmountEvent}
                    deleteStockEvent={this.deleteStockEvent}
                  />
                ))}
              </ul>

            </section>

            <ColorPicker isShowedColorPicker={this.state.isShowedColorPicker} changeColorEvent={this.changeColorEvent}/>
          </section>

          <div className={`index-suggest_button ${this.state.isShowedSuggestButton ? 'is_show' : ''}`}>
            <h3 className="index-sub_title">ポートフォリオに追加</h3>
            <Suggest className={`index-suggest_button--input`} setIdToLocalStorage={this.setIdToLocalStorage}/>

            <h4 className="index-suggest_button--sub_title">上記で見つからない場合は申請できます</h4>
            <input className={`index-suggest_button--add`} type="text" onChange={this.changeStockValueEvent} value={this.state.getStockValue} placeholder={`新しい株をDBに追加`}/>
            <button className={`index-suggest_button--submit`} onClick={this.getStockEvent}>株をDBに追加する</button>
          </div>

          <div className={`index-right_bottom_button`} onClick={this.openSuggestButtonEvent}>
            <Fab color="primary" aria-label="add">
              <AddIcon/>
            </Fab>
          </div>

        </React.StrictMode>
      </div>
    )
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <div>
    <meta name="mobile-web-app-capable" content="yes"/>
    <Index/>
  </div>
)

reportWebVitals()

