import * as React from 'react'
import { ChromePicker } from 'react-color'

class ColorPicker extends React.Component {
  state = {
    background: '#ffffff'
  }
  handleChangeComplete = () => {
    this.props.changeColorEvent(this.state.background, this.props.stockId)
  }

  changeColorEvent = (color) => {
    this.setState({
      background: color.hex
    })
  }

  render () {
    return (
      <section className={`picker_section ${this.props.isShowedColorPicker ? 'is_showed' : ''}`}>
        <div className={`picker_section--background`} onTouchStart={this.handleChangeComplete}></div>
        <ChromePicker
          className={`picker_section--color_picker`}
          color={this.state.background}
          onChangeComplete={this.changeColorEvent}
        />
      </section>
    )
  }
}

export default ColorPicker