import * as React from 'react'

class MonthlyChart extends React.Component {

  getMaxValue = (monthlyStocks) => {
    const array = [...monthlyStocks]
    return array.sort((a, b) => {
      if (a > b) {
        return -1
      } else {
        return 1
      }
    })[0]
  }

  setValueToChart (price, maxValue) {
    if (isNaN((price / maxValue) * 100)) {
      return 0
    }
    if (!isNaN((price / maxValue) * 100)) {
      return (price / maxValue) * 100
    }
  }

  getMonthlyPriceArray = () => {
    const monthlyPriceStocks = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    this.props.stocks.forEach((stock) => {
      stock.dividend_months.forEach((month) => {
        const dividendPrice = Number((stock.stock_dividend_price * stock.amount) / stock.dividend_months.length)
        monthlyPriceStocks[month.id - 1] += dividendPrice
      })
    })
    return monthlyPriceStocks
  }

  render () {
    const monthlyPrice = this.getMonthlyPriceArray()
    const maxValue = this.getMaxValue(monthlyPrice)
    return (
      <div className={`monthly_chart`}>
        {monthlyPrice.map((price, index) => {
            const childStyle = { height: this.setValueToChart(price, maxValue) }
            return (
              <div key={index} className={`monthly_chart--box`}>
                <div className={`monthly_chart--bar`}>
                  <span className={`monthly_chart--child_bar`} style={childStyle}></span>
                </div>
                <span className={`monthly_chart--price_label`}>{Math.floor(price)}円</span>
                <span className={`monthly_chart--month_label`}>{index + 1}月</span>
              </div>
            )
          }
        )}
      </div>
    )
  }
}

export default MonthlyChart