import * as React from 'react'
import './PieChart.css'
import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'

ChartJS.register(ArcElement, Tooltip, Legend)

class PieChart extends React.Component {

  render () {
    const dataArray = this.props.stocks.map((stock) => {
      return stock.stock_dividend_price * stock.amount
    })
    const tickerArray = this.props.stocks.map((stock) => {
      return stock.ticker
    })
    const colorArray = this.props.stocks.map((stock)=> {
      return stock.color
    })

    const data = {
      labels: tickerArray,
      datasets: [{
        label: 'My First Dataset',
        data: dataArray,
        backgroundColor: colorArray,
        hoverOffset: 0
      }]
    }
    const options = {
      plugins: {
        legend: {
          display: false
        }
      }
    }
    return (
      <div>
        <Doughnut data={data} options={options}/>
      </div>
    )
  }
}

export default PieChart